import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const DigiatlMarketing = () => {

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoftware.co.in/digiatlmarketing" />
                    <meta name="keywords" content="Technology iCloudsoft, Software Development Company in Pune" />
                    <title> Digital Marketing | AI & Software Development Company in Pune | India </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#000C14" }}>
                    <nav className="navbar navbar-expand-lg navbar p-0" >
                        <div className="container ">
                            <div className='LogoSection'>
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-end ">
                                    <li className="nav-item MainPaddingNav" >
                                        <a className="nav-link active " aria-current="page" href="/">HOME</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/services">SERVICES</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/technology">TECHNOLOGY</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/digiatlmarketing">DIGITAL MARKETING</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/ai">AI</a>
                                    </li>

                                    <li className="nav-item dropdown MainPaddingNav">
                                        <a className="nav-link" href='/solutions' >
                                            SOlUTIONS  <span className=''>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </a>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/solutions"> Solutions </a>
                                            </li>

                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/management"> Management </a>
                                            </li>

                                            <li className=''>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/industries"> Industries </a>
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/about">ABOUT US</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/portfolio">PORTFOLIO</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block">
                    <nav className="navbar navbar-expand-lg p-0 " >
                        <div className="container-fluid p-0 ">
                            <div className='LogoSection' style={{ backgroundColor: "#202734" }} >
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler" style={{ backgroundColor: "#202734" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "100%", marginLeft: "0px" }} >
                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/technology" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > TECHNOLOGY </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/digiatlmarketing" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > DIGITAL MARKETING </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/ai" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > AI </b> </a>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#2bc0c1" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className=' '>
                                                    <div className="row d-flex justify-content-start  fs-6">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                            <a className='text-decoration-none' href="/solutions">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Solutions </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/management">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Management </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/industries">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Industries </b></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

            <section>
                <div className="container-fluid ServicesFirstSection" style={{ backgroundImage: `url(${require("../Img/DM.jpg")})`, backgroundAttachment: 'local', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: "top" }}>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "rgb(27, 26, 46)" }}>
                    <div className="container-lg " style={{ padding: "20px" }}>
                        <div className="row d-flex justify-content-center align-items-center  MainGradient">
                            <div className="col-xl-5 col-md-5 p-lg-0">
                                <div className='AISectionFirst'>
                                    <p>
                                        Boosting Your Brand by Harnessing the Potential of Online Marketing Tactics
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-7 col-md-7 p-lg-0">
                                <div className='AISectionSecond'>
                                    <p>
                                        Our team specializes in designing and developing digital experiences that leave a lasting impact on our clients and their audiences.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pt-2 pb-4 d-lg-none d-md-block" style={{ backgroundColor: "rgb(27, 26, 46)" }}>
                    <div className="container-lg p-3">
                        <div className="row d-flex justify-content-center align-items-center  MainGradient">
                            <div className="col-xl-5 col-md-5 p-0">
                                <div className='AISectionFirst'>
                                    <p>
                                        Boosting Your Brand by Harnessing the Potential of Online Marketing Tactics
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-7 col-md-7 p-0">
                                <div className='AISectionSecond'>
                                    <p>
                                        Our team specializes in designing and developing digital experiences that leave a lasting impact on our clients and their audiences.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-3 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "rgb(27, 26, 46)" }}>
                    <div className="container-lg p-2 pb-lg-3 mb-4">
                        <div className="row g-4 d-flex justify-content-center align-items-center  ">
                            <div className="col-xl-6 col-md-6 ">
                                <div>
                                    <img className='w-100' src={require("../Img/AboutDM.jpg")} alt="ABOUT Digital Marketing" title='ABOUT Digital Marketing' />
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 ">
                                <div className='AboutSectionHome'>
                                    <h6>
                                        ABOUT Digital Marketing
                                    </h6>
                                    <h1 className='' style={{ fontSize: "20px" }}>
                                        iCloudsoft Technology is Digital Marketing Company for Entrepreneurs to Business in Pune
                                    </h1>
                                    <p>
                                        Digital marketing plays a crucial role in helping entrepreneurs grow their businesses by providing a platform to reach a wider audience and create brand awareness. For entrepreneurs, implementing an effective digital marketing strategy is essential to stay competitive in today's market. By utilizing tools such as social media, content marketing, email campaigns, and search engine optimization, entrepreneurs can target their desired demographic and drive traffic to their products or services.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-2 d-lg-none d-md-block" style={{ backgroundColor: "rgb(27, 26, 46)" }}>
                    <div className="container-lg  pb-lg-3 mb-4">
                        <div className="row g-4 d-flex justify-content-center align-items-center  ">
                            <div className="col-xl-6 col-md-6 ">
                                <div>
                                    <img className='w-100' src={require("../Img/AboutDM.jpg")} alt="ABOUT Digital Marketing" title='ABOUT Digital Marketing' />
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6 ">
                                <div className='AboutSectionHome'>
                                    <h6>
                                        ABOUT Digital Marketing
                                    </h6>
                                    <h5 className='mb-3' style={{ fontSize: "16.5px", fontWeight: "600" }}>
                                        iCloudsoft Technology is Digital Marketing Company for Entrepreneurs to Business in Pune
                                    </h5>
                                    <p>
                                        Digital marketing plays a crucial role in helping entrepreneurs grow their businesses by providing a platform to reach a wider audience and create brand awareness. For entrepreneurs, implementing an effective digital marketing strategy is essential to stay competitive in today's market. By utilizing tools such as social media, content marketing, email campaigns, and search engine optimization, entrepreneurs can target their desired demographic and drive traffic to their products or services.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pt-5 pb-5 d-md-none d-none d-lg-block" style={{ backgroundColor: "rgb(27, 26, 46)" }}>
                    <div className="container-lg">
                        <div className="row g-4 d-flex justify-content-around align-items-center">
                            <div className="col-xl-5 col-lg-5 ">
                                <div className='OurServices'>
                                    <h6>
                                        ABOUT SERVICE
                                    </h6>
                                    <h2>
                                        Expert Digital Marketing Services to Expand Your Brand
                                    </h2>
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6">
                                <div className='OurServices'>
                                    <p>
                                        Digital Marketing is important for business owner to stay updated on the latest trends in digital marketing and adapt their strategies accordingly to maximize their online presence and connect with potential customers in a meaningful way. By investing time and resources into digital marketing efforts, entrepreneurs can establish a strong online presence, expand their reach, and ultimately drive business growth.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row g-4 pt-lg-5 mt-5">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/socialmedia'>
                                    <div className='AboutService '>
                                        <div className='d-flex justify-content-center'>
                                            <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                <img className='' src={require("../Img/SocialMediaIcon1.png")} alt="Social Media Marketing" title='Social Media Marketing' />
                                            </div>
                                        </div>
                                        <h2>
                                            Social Media Marketing
                                        </h2>
                                        <p>
                                            Gain a competitive edge in the digital world with Social Media Marketing solutions.
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/seo'>
                                    <div className='AboutService'>
                                        <div className='d-flex justify-content-center'>
                                            <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                <img className='' src={require("../Img/SeoIcon.png")} alt="SEO" title='SEO' />
                                            </div>
                                        </div>
                                        <h2>
                                            SEO
                                        </h2>
                                        <p>
                                            Maximize your online reach and achieve long-term success with our customized SEO solutions.
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/emailmarketing'>
                                    <div className='AboutService'>
                                        <div className='d-flex justify-content-center'>
                                            <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                <img className='' src={require("../Img/EmailMarketingIcon.png")} alt="Email Marketing" title='Email Marketing' />
                                            </div>
                                        </div>
                                        <h2>
                                            Email Marketing
                                        </h2>
                                        <p>
                                            Elevate your marketing efforts with our Email Marketing platform.
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/marketingautomation'>
                                    <div className='AboutService'>
                                        <div className='d-flex justify-content-center'>
                                            <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                <img className='' src={require("../Img/MarketingAutomationIcon1.png")} alt="Marketing Automation" title='Marketing Automation' />
                                            </div>
                                        </div>
                                        <h2>
                                            Marketing Automation
                                        </h2>
                                        <p>
                                            Spend less time on routine tasks and more time on driving results for your business.
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/videomarketing'>
                                    <div className='AboutService'>
                                        <div className='d-flex justify-content-center'>
                                            <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                <img className='' src={require("../Img/VideoMarketingIcon.png")} alt="Video Marketing" title='Video Marketing' />
                                            </div>
                                        </div>
                                        <h2>
                                            Video Marketing
                                        </h2>
                                        <p>
                                            We help you reach new heights and achieve your business goals through the power of video.
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/mobilemarketing'>
                                    <div className='AboutService'>
                                        <div className='d-flex justify-content-center'>
                                            <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                <img className='' src={require("../Img/MobileMarketingIcon.png")} alt="Mobile Marketing" title='Mobile Marketing' />
                                            </div>
                                        </div>
                                        <h2>
                                            Mobile Marketing
                                        </h2>
                                        <p>
                                            Our Mobile Marketing solution is designed to help businesses of all sizes reach their target audience effectively on smartphones and tablets.
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pb-5 d-lg-none d-md-block" style={{ backgroundColor: "rgb(27, 26, 46)" }}>
                    <div className="container-lg">
                        <div className="row g-4 d-flex justify-content-around align-items-center">
                            <div className="col-xl-5 col-lg-5 ">
                                <div className='OurServices'>
                                    <h6>
                                        ABOUT SERVICE
                                    </h6>
                                    <h2 className='' style={{ fontSize: "30px" }}>
                                        Expert Digital Marketing Services to Expand Your Brand
                                    </h2>
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6">
                                <div className='OurServices'>
                                    <p>
                                        Digital Marketing is important for business owner to stay updated on the latest trends in digital marketing and adapt their strategies accordingly to maximize their online presence and connect with potential customers in a meaningful way. By investing time and resources into digital marketing efforts, entrepreneurs can establish a strong online presence, expand their reach, and ultimately drive business growth.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row g-4 pt-lg-5 mt-5">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/socialmedia'>
                                    <div className='AboutService '>
                                        <div className='d-flex justify-content-center'>
                                            <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                <img className='' src={require("../Img/SocialMediaIcon1.png")} alt="Social Media Marketing" title='Social Media Marketing' />
                                            </div>
                                        </div>
                                        <h2>
                                            Social Media Marketing
                                        </h2>
                                        <p>
                                            Gain a competitive edge in the digital world with Social Media Marketing solutions.
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/seo'>
                                    <div className='AboutService'>
                                        <div className='d-flex justify-content-center'>
                                            <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                <img className='' src={require("../Img/SeoIcon.png")} alt="SEO" title='SEO' />
                                            </div>
                                        </div>
                                        <h2>
                                            SEO
                                        </h2>
                                        <p>
                                            Maximize your online reach and achieve long-term success with our customized SEO solutions.
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/emailmarketing'>
                                    <div className='AboutService'>
                                        <div className='d-flex justify-content-center'>
                                            <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                <img className='' src={require("../Img/EmailMarketingIcon.png")} alt="Email Marketing" title='Email Marketing' />
                                            </div>
                                        </div>
                                        <h2>
                                            Email Marketing
                                        </h2>
                                        <p>
                                            Elevate your marketing efforts with our Email Marketing platform.
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/marketingautomation'>
                                    <div className='AboutService'>
                                        <div className='d-flex justify-content-center'>
                                            <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                <img className='' src={require("../Img/MarketingAutomationIcon1.png")} alt="Marketing Automation" title='Marketing Automation' />
                                            </div>
                                        </div>
                                        <h2>
                                            Marketing Automation
                                        </h2>
                                        <p>
                                            Spend less time on routine tasks and more time on driving results for your business.
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/videomarketing'>
                                    <div className='AboutService'>
                                        <div className='d-flex justify-content-center'>
                                            <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                <img className='' src={require("../Img/VideoMarketingIcon.png")} alt="Video Marketing" title='Video Marketing' />
                                            </div>
                                        </div>
                                        <h2>
                                            Video Marketing
                                        </h2>
                                        <p>
                                            We help you reach new heights and achieve your business goals through the power of video.
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <a className='text-decoration-none' href='/mobilemarketing'>
                                    <div className='AboutService'>
                                        <div className='d-flex justify-content-center'>
                                            <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                <img className='' src={require("../Img/MobileMarketingIcon.png")} alt="Mobile Marketing" title='Mobile Marketing' />
                                            </div>
                                        </div>
                                        <h2>
                                            Mobile Marketing
                                        </h2>
                                        <p>
                                            Our Mobile Marketing solution is designed to help businesses of all sizes reach their target audience effectively on smartphones and tablets.
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container-lg pt-5 pb-5  mb-4">
                        <div className='AIsectionBottom'>
                            <h2>
                                The impact of digital marketing on savvy entrepreneurs
                            </h2>
                            <h6>
                                In today's business landscape, digital marketing has become a powerful tool for  business professionals.
                            </h6>
                        </div>
                        <div className="row g-4 pt-5 mb-sm-5">
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <div className='AboutServiceBottom'>
                                    <div className='d-flex justify-content-center'>
                                        <div className='CommonIconAboutBottom d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/Differnt.png")} alt="Different" title='Different' />
                                        </div>
                                    </div>
                                    <h2>
                                        Different
                                    </h2>
                                    <p>
                                        Our company <b> Different </b> itself in the field of digital marketing through a combination of cutting-edge technology, personalized strategies, and a dedicated team of experts.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <div className='AboutServiceBottom'>
                                    <div className='d-flex justify-content-center'>
                                        <div className='CommonIconAboutBottom d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/Responsive.png")} alt="Responsive" title='Responsive' />
                                        </div>
                                    </div>
                                    <h2>
                                        Responsive
                                    </h2>
                                    <p>
                                        Prioritizing <strong> Responsiveness </strong> in digital marketing is essential for staying relevant, engaging with the target audience effectively, and achieving sustainable growth.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <div className='AboutServiceBottom'>
                                    <div className='d-flex justify-content-center'>
                                        <div className='CommonIconAboutBottom d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/Creative.png")} alt="Creative" title='Creative' />
                                        </div>
                                    </div>
                                    <h2>
                                        Creative
                                    </h2>
                                    <p>
                                        The <strong> Creative </strong> opportunities afforded by digital marketing empower brands to connect with and inspire their audiences
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <div className='AboutServiceBottom'>
                                    <div className='d-flex justify-content-center'>
                                        <div className='CommonIconAboutBottom d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/Strategy.png")} alt="Strategy" title='Strategy' />
                                        </div>
                                    </div>
                                    <h2>
                                        Strategy
                                    </h2>
                                    <p>
                                        Digital marketing <b> Strategy </b> is essential for businesses to effectively reach their target audience and achieve their marketing goals in the ever-evolving online landscape.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default DigiatlMarketing