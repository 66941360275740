import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Solution = () => {
    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="https://www.icloudsoftware.co.in/solutions" />
                    <meta name="keywords" content="Solutions iCloudsoft, Software Development Company in Pune,innovation, collaboration,  commitment to excellence,leadership,10 years of consonant reviews" />
                    <title> Solutions | AI & Software Development Company in Pune | India </title>
                </Helmet>
            </HelmetProvider>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#000C14" }}>
                    <nav className="navbar navbar-expand-lg navbar p-0" >
                        <div className="container ">
                            <div className='LogoSection'>
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-end ">
                                    <li className="nav-item MainPaddingNav" >
                                        <a className="nav-link active " aria-current="page" href="/">HOME</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/services">SERVICES</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/technology">TECHNOLOGY</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/digiatlmarketing">DIGITAL MARKETING</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/ai">AI</a>
                                    </li>

                                    <li className="nav-item dropdown MainPaddingNav">
                                        <a className="nav-link" href='/solutions' >
                                            SOlUTIONS  <span className=''>
                                                <i class="fa-solid fa-angle-down"></i>
                                            </span>
                                        </a>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/solutions"> Solutions </a>
                                            </li>

                                            <li className='border-bottom'>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/management"> Management </a>
                                            </li>

                                            <li className=''>
                                                <a className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '600', color: "#fff", letterSpacing: "1px" }} href="/industries"> Industries </a>
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/about">ABOUT US</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/portfolio">PORTFOLIO</a>
                                    </li>

                                    <li className="nav-item MainPaddingNav">
                                        <a className="nav-link " aria-current="page" href="/contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block">
                    <nav className="navbar navbar-expand-lg p-0 " >
                        <div className="container-fluid p-0 ">
                            <div className='LogoSection' style={{ backgroundColor: "#202734" }} >
                                <a className="text-decoration-none" href="/">
                                    <h4>
                                        <span>I</span>
                                        cloudsoft
                                    </h4>
                                </a>
                            </div>
                            <button className="navbar-toggler" style={{ backgroundColor: "#202734" }} type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
                            </button>
                            <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "100%", marginLeft: "0px" }} >
                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/technology" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > TECHNOLOGY </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/digiatlmarketing" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > DIGITAL MARKETING </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/ai" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > AI </b> </a>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px", backgroundColor: "#2bc0c1" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className=' '>
                                                    <div className="row d-flex justify-content-start  fs-6">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                            <a className='text-decoration-none' href="/solutions">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Solutions </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/management">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Management </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </a>
                                                            <a className='text-decoration-none' href="/industries">
                                                                <div className='dropdown-item text-white fs-6 '><b className='' style={{ fontWeight: "600", fontSize: "14px", letterSpacing: "0.6px" }}> Industries </b></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </a>
                                    </li>

                                    <li className='nav-item text-start pb-2 mt-2' >
                                        <a className="text-white text-decoration-none fs-6  " href="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className='TopHeader'>
                                <p className='mt-3'>
                                    <a className="text-decoration-none" href='/'>
                                        <span className='TopHeaderFirst'>
                                            HOME
                                        </span>
                                    </a>
                                    <span className='TopHeaderIcon'>
                                        <i class="fa-sharp fa-solid fa-angle-right"></i>
                                    </span>
                                    <span className='TopHeaderSecond'>
                                        SOLUTIONS
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container-lg p-xl-4 pb-lg-3 mb-4">
                        <div className='ReasonsHireUs'>
                            <h2>
                                Solutions
                            </h2>
                            <h1>
                                Comprehensive AI & Software Development Solutions in Pune
                            </h1>
                        </div>
                        <div className="row g-4 p-xl-2 mb-sm-5">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-1.png")} alt="CRM" title='CRM' />
                                    <h3>
                                        CRM
                                    </h3>
                                    &nbsp;
                                    <p>
                                        The goal of CRM is to improve customer retention and drive sales growth by better understanding and responding to customer needs and preferences. It refers to a set of strategies, technologies, and practices that organizations use to manage and analyze interactions with customers and prospects.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-2.png")} alt="Marketing" title='Marketing' />
                                    <h3>
                                        Marketing & Advertising
                                    </h3>
                                    &nbsp;
                                    <p>
                                        We offer marketing software development to help enterprises improve the efficiency of their marketing plans and enable them to use proper software. The goal of marketing is to understand customer needs and preferences and to develop and promote products or services that meet those needs.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-3.png")} alt="Human Resources" title='Human Resources' />
                                    <h3>
                                        Human Resources
                                    </h3>
                                    &nbsp;
                                    <p>
                                        iCloudsoft provides technical product management consulting, product development, UX and UI design, QA, maintenance, and support services to create powerful HR systems that help you increase competitiveness in the SaaS HR software market and reduce product development costs and risks.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-4.png")} alt="eLearning" title='eLearning' />
                                    <h3>
                                        eLearning
                                    </h3>
                                    &nbsp;
                                    <p>
                                        iCloudsoft has been building eLearning solutions that drive the digital transformation of corporate learning, resulting in human capital ROI growth. The iCloudsoft team is ready to streamline your corporate learning with technology or modernize your current eLearning software.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-5.png")} alt="ERP" title='ERP' />
                                    <h3>
                                        ERP
                                    </h3>
                                    &nbsp;
                                    <p>
                                        iCloudsoft offers end-to-end ERP development and support to help you get deep visibility into business operations, streamline information flows, and optimize business costs. ERP is an application that automates business processes and provides insights and internal controls.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-6.png")} alt="Data Analysis" title='Data Analysis' />
                                    <h3>
                                        Data Analysis
                                    </h3>
                                    &nbsp;
                                    <p>
                                        Data analytics implies building an infrastructure for data aggregation,
                                        analysis, and reporting. An experienced provider of data analytics
                                        services, iCloudsoft delivers on simple and complex needs with
                                        tailored business analytics solutions.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-1.png")} alt="Ecommerce" title='Ecommerce' />
                                    <h3>
                                        Ecommerce
                                    </h3>
                                    &nbsp;
                                    <p>
                                        We create memorable websites that sell. With our
                                        eCommerce expertise we work on every element of your
                                        website that perfectly aligns with your brand voice
                                        and volumes. If you are proud of your brand, our eCommerce
                                        development expertise will make you proud of your website.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-2.png")} alt="Web Portals" title='Web Portals' />
                                    <h3>
                                        Web Portals
                                    </h3>
                                    &nbsp;
                                    <p>
                                        Web portal development is an end-to-end service to plan and implement a secure and user-friendly digital space to serve your customers, partners, and employees. Web portals enable self-service functions for target users and give access to services, products, information, and communication tools. iCloudsoft can help you create a web portal of any type to cover your specific needs for engaging with the audience.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className='ServicesSection'>
                                    <img src={require("../Img/ServicesSection3-3.png")} alt="CMS" title='CMS' />
                                    <h3>
                                        CMS
                                    </h3>
                                    &nbsp;
                                    <p>
                                        iCloudsoft competently delivers custom CMS solutions that combine responsive UI, rich functionality, strong data security, and high scalability. Content management system (CMS) development is needed to create intuitive, customizable, scalable, and SEO-oriented solutions for handling your corporate content.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Solution